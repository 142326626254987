import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default class extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title={"Thank You"}/>
        <div className={"row my-3"}>
          <div className={"col"}>
            <div className={"alert alert-success"}>
              <h3>Thank you for requesting a demo! A PSS Data Garage Advisor™ will contact you as soon as possible.</h3>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
